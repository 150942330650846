import { DiEntityIdentifier } from '@core/di/types';

function createTokens<Key extends string>(x: Record<Key, DiEntityIdentifier>) {
  return x;
}

export const DI_TOKENS = createTokens({
  configService: 'config-service',
  appHttpClientService: 'app-http-client-service',
  configHttpClientService: 'config-http-client-service',
  layoutVM: 'layout-vm',
  ratesVM: 'rates-vm',
  localesVM: 'locales-vm',
  baseService: 'base-service',
  authService: 'auth-service',
  storageService: 'storage-service',
  loggerService: 'logger-service',
  walletsService: 'wallets-service',
  ratesService: 'rates-service',
  ordersService: 'orders-service',
  usersService: 'users-service',
  cardService: 'card-service',
  KYCService: 'kyc-service',
  errorMessagesService: 'error-messages-service',
  worldpayService: 'worldpay-service',
  stripeService: 'stripe-service',
  paymentService: 'payment-service',
  worldpayStrategy: 'worldpay-strategy',
  stripeStrategy: 'stripe-strategy',
  productsService: 'products-service',
  productsVM: 'products-view-model',
  ordersSmartCoinService: 'orders-smart-coin-service',
  smartCoinTrackingService: 'smart-coin-tracking-service',
  smartCoinNotificationService: 'smart-coin-notification-service',
  smartCoinRepositoryService: 'smart-coin-repository-service',
  notificationService: 'notification-service',
  smartCoinDetailsDialogViewModel: 'smart-coin-details-dialog-view-model',
  transactionsNotificationService: 'transactions-notification-service',
  secure3DRepositoryService: 'secure-3d-repository-service',
  navigationViewModel: 'navigation-vm',
  cardsCooldownCheckVM: 'cards-cooldown-check-vm',
});
